<template>
  <div>
    <TagbyFilterV2
      :initialParams="state.filterParams"
      :categoryOptions="categoryOptions"
      :leftWidth="3"
      @search="search"
    />
    <TagbyList
      v-model="state.currentPage"
      :items="getters.items"
      :fields="tableColumns"
      :busy="state.isBusy"
      :current-page="state.currentPage"
      :total-rows="state.totalRows"
      :per-page="state.perPage"
      @page-change="changePage"
      @sort-changed="changeSort"
    >
      <template #cell(created_at)="data">
        <TagbyDatetimeColumn :value="data.value" />
      </template>
    </TagbyList>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BLink,
  BButton,
} from 'bootstrap-vue'
import useInitialize from './useInitialize'
import useItems from './useItems'
import TagbyFilterV2 from '@/components/TagbyFilterV2.vue'
import TagbyList from '@/components/TagbyList.vue'
import TagbyDatetimeColumn from '@/components/TagbyDatetimeColumn.vue'
import state from './state'
import getters from './getters'

export default defineComponent({
  components: {
    BLink,
    BButton,
    TagbyFilterV2,
    TagbyList,
    TagbyDatetimeColumn,
  },
  setup() {
    const {
      initialize,
    } = useInitialize()
    initialize()

    const {
      categoryOptions,
      hasRequestTime,
      tableColumns,
      search,
      searchList,
      changePage,
      changeSort,
    } = useItems()
    searchList()

    return {
      state,
      getters,
      categoryOptions,
      hasRequestTime,
      tableColumns,
      search,
      searchList,
      changePage,
      changeSort,
    }
  },
})
</script>
